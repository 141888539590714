import React from 'react';

import PageLayout from '../../../components/PageLayout';

import NotFound from './NotFound';

const title = 'Page Not Found';

function action() {
  return {
    title,
    chunks: ['not-found'],
    component: (
      <PageLayout>
        <NotFound key="notfound" title={title} />
      </PageLayout>
    ),
    status: 404,
  };
}

export default action;
