exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3KNyb{padding-top:80px;padding-left:20px;padding-right:20px}._35DJr{margin:0 auto;padding:0 0 40px;max-width:var(--max-content-width);max-width:var(--max-content-width)}._35DJr h1, ._35DJr h2, ._35DJr p{text-align:center}", ""]);

// exports
exports.locals = {
	"root": "_3KNyb",
	"container": "_35DJr"
};